<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:body>
        <b-row>
          <b-col sm="5">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('warehouseFarmer.delivery_date_from')"
              label-for="date"
            >
              <flat-pickr class="form-control"
                v-model="search.from_date"
                :config="{}"
                placeholder="Select Date"
              ></flat-pickr>
            </b-form-group>
          </b-col>
          <b-col sm="5">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('warehouseFarmer.delivery_date_to')"
              label-for="to_date"
            >
              <flat-pickr class="form-control"
                v-model="search.to_date"
                :config="{}"
                placeholder="Select Date"
              ></flat-pickr>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouseFarmer.delivery_schedule_list')}}</h4>
          </template>
          <!-- <template v-slot:headerAction>
            <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
              {{  $t('globalTrans.add_new') }}
            </b-button>
          </template> -->
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(warehouse_name)="data">
                      <span class="capitalize">{{ data.item.warehouse_name }}</span>
                    </template>
                    <template v-slot:cell(farmer_land_area)="data">
                      {{ $n(data.item.farmer_land_area, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                    </template>
                    <template v-slot:cell(delivery_date)="data">
                      <span>{{ data.item.delivery_date | dateFormat }}</span>
                    </template>
                    <template v-slot:cell(receipt)="data">
                    <b-button class="mr-1" title="Delivery Receipt" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="setRow(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
     <b-modal id="modal-5" size="lg" :title="$t('warehouseFarmer.delivery_receipt')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <Details :id="testId" :farmer_id="farmer_id" />
    </b-modal>

  </b-container>
</template>
<script>
import Details from './Details'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { farDeliveryScheduleList, userStatus } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details
  },
  data () {
    return {
      search: {
        from_date: '',
        to_date: ''
      },
    farmerList: [],
    testId: 0,
    farmer_id: 0
    }
  },
  computed: {
    formTitle () {
       return (this.testId === 0) ? this.$t('warehouse_config.warehouse') + ' ' + this.$t('globalTrans.entry') : this.$t('warehouse_config.warehouse_add_entry') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('warehouse_config.warehouse'), class: 'text-left' },
          { label: this.$t('warehouseFarmer.product_type'), class: 'text-left' },
          { label: this.$t('warehouseFarmer.product_name'), class: 'text-left' },
          { label: this.$t('warehouseFarmer.land_area'), class: 'text-left' },
          { label: this.$t('warehouseFarmer.delivery_date'), class: 'text-left' },
          { label: this.$t('warehouseFarmer.receipt'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'warehouse_name_bn' },
          { key: 'commodity_group_name_bn' },
          { key: 'commodity_name_bn' },
          { key: 'farmer_land_area' },
          { key: 'delivery_date' },
          { key: 'receipt' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'warehouse_name' },
          { key: 'commodity_group_name' },
          { key: 'commodity_name' },
          { key: 'farmer_land_area' },
          { key: 'delivery_date' },
          { key: 'receipt' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
 async created () {
    this.checkApprovedUser()
  },
  methods: {
    async searchData () {
      this.loadData()
    },
    async loadData () {
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: true, listReload: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      const response = await RestApi.getData(authServiceBaseUrl, '/user/farmer-details')
        if (response.success) {
          this.farmerList = response.data
        }
       const result = await RestApi.getData(warehouseServiceBaseUrl, farDeliveryScheduleList, params)
      if (result.success) {
        this.$store.dispatch('setList', this.ListShow(result.data.data))
        // this.deliverySchedule = this.ListShow(result.data.data)
        this.paginationData(result.data)
        this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: false })
      } else {
        this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: false })
      }
    },
    ListShow (data) {
      let tmpFarmerData = {}
      const listData = data.map(item => {
        tmpFarmerData = this.farmerList.find(farmer => farmer.username === item.farmer_id)
        const orgData = {
           farmer_name: tmpFarmerData !== undefined ? tmpFarmerData.name : '',
           farmer_name_bn: tmpFarmerData !== undefined ? tmpFarmerData.name_bn : '',
           farmer_land_area: tmpFarmerData !== undefined ? tmpFarmerData.land_area : ''
           }
        return Object.assign({}, item, orgData)
      })
      return listData
    },
    setRow (item) {
        this.testId = item.id
        this.farmer_id = item.farmer_id
    },
    async checkApprovedUser () {
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const authUser = this.$store.state.Auth.authUser.username
        const result = await RestApi.getData(authServiceBaseUrl, userStatus + authUser)
        const status = result.status
        if (status === 1) {
            this.loadData()
        } else {
            this.$router.push({ name: 'external_user.warehouse_farmer' + '.dashboard' })
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
