<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <!-- <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_report.yearly-product-received-and-delivery-report') }}</h4>
          </template> -->
          <template v-slot:headerAction>
                <b-button variant="primary" @click="pdfExport" class="mr-2">
                {{  $t('globalTrans.export_pdf') }}
                </b-button>
          </template>
          <template v-slot:body>
            <b-overlay>
                <b-row>
                    <b-col>
                        <list-report-head :base-url="warehouseServiceBaseUrl" uri="master-warehouse-report-heading/detail" :org-id="search.org_id">
                          {{ $t('externalUserIrrigation.sossho_gudam_rin_karzokrom') }}
                        </list-report-head>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col></b-col>
                </b-row>
              <b-row mt-5>
                <b-col md="12" class="table-responsive">
                  <b-col md="12" class="table-responsive">
                  <div>
                     <h6>{{ $t('warehouseFarmer.date') }}:{{ (currentDate)  }}</h6><br />
                     <h6 style="margin-top:-20px">{{ $t('warehouseFarmer.farmer_id') }}: {{  $n(deliverySchedule.farmer_id, { useGrouping: false }) }}</h6><br />
                     <h6 style="margin-top:-20px">{{ $t('warehouseFarmer.farmer_name') }}: {{ $i18n.locale === 'bn' ? $t(farmerList.name_bn) : farmerList.name }}</h6><br />
                     <h6 style="margin-top:-20px">{{ $t('warehouseFarmer.father_name') }}: {{ $i18n.locale === 'bn' ? $t(farmerList.father_name_bn) : farmerList.father_name }}</h6><br />
                     <h6 style="margin-top:-20px">{{ $t('warehouseFarmer.village') }}:  {{ getUnion(farmerList.union_id)}}</h6><br />
                     <h6 style="margin-top:-20px">{{ $t('warehouseFarmer.district') }}:  {{ getDistrict(farmerList.district_id)}}</h6><br />
                      <b-table-simple bordered hover small caption-top responsive aria-hidden="loading | listReload ? 'true' : null">
                        <!-- <caption class="text-center" style="font-weight:bold; text-color:white">{{ $t('warehouse_report.yearly-product-received-and-delivery-report') }}</caption> -->
                        <colgroup><col><col></colgroup>
                        <colgroup><col><col><col></colgroup>
                        <colgroup><col><col></colgroup>
                        <b-thead>
                          <b-tr>
                            <b-th>{{ $t('warehouseFarmer.sack_quantity') }}</b-th>
                            <b-th>{{ $t('warehouseFarmer.gain_description') }}</b-th>
                            <b-th>{{ $t('warehouseFarmer.sackNo') }}</b-th>
                            <b-th colspan="2" scope="colgroup">{{ $t('warehouseFarmer.weight') }}</b-th>
                          </b-tr>
                           <b-tr>
                             <th scope="col"></th>
                              <th scope="col"></th>
                              <th scope="col"></th>
                              <th scope="col">{{ $t('warehouseFarmer.quintal') }}</th>
                              <th scope="col">{{ $t('warehouseFarmer.kilogram') }}</th>
                          </b-tr>
                            <b-tr>
                             <th scope="col">
                               {{ $i18n.locale === 'bn' ? $n(deliverySchedule.sack_quantity) : deliverySchedule.sack_quantity }}
                              </th>
                              <th scope="col"> {{ $i18n.locale === 'bn' ? $t(deliverySchedule.commodity_group_name_bn) : deliverySchedule.commodity_group_name }} >> {{ $i18n.locale === 'bn' ? $t(deliverySchedule.commodity_name_bn) : deliverySchedule.commodity_name }}</th>
                              <th></th>
                              <th scope="col">{{ $i18n.locale === 'bn' ? $n(deliverySchedule.quintal) : deliverySchedule.quintal }}</th>
                              <th scope="col"></th>
                          </b-tr>
                        </b-thead>
                      </b-table-simple>
                      <div class="receipt-footer">
                        <b-row>
                          <b-col class="left">
                            <p></p>
                            <p>{{ $t('warehouseFarmer.application_loan_rate') }}: ............................................</p>
                            <p>{{ $t('warehouseFarmer.in_words') }}</p>
                          </b-col>
                          <b-col class="right">
                            <p>{{ $t('warehouseFarmer.total') }}: ...........................</p>
                            <p class="text-right">
                              <span class="keeper">{{ $t('warehouseFarmer.warehouse_kepper') }}</span>
                            </p>
                          </b-col>
                        </b-row>
                      </div>
                  </div>
                </b-col>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { authServiceBaseUrl, warehouseServiceBaseUrl } from '@/config/api_config'
import { farmerProfile, farDeliveryDetails } from '../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import excel from 'vue-excel-export'
import ExportPdf from '@/Utils/export-pdf'
import Vue from 'vue'
Vue.use(excel)

export default {
  props: ['testId', 'farmer_id'],
  components: {
      ListReportHead
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        slOffset: 1
      },
      deliveryReceipt: '',
      deliverySchedule: [],
      currentDate: '',
      farmerList: '',
      search: {
        org_id: 13
      },
      rows: [],
      warehouseServiceBaseUrl: warehouseServiceBaseUrl,
      ListReportHead: ListReportHead
    }
  },
  created () {
    this.loadData()
    this.getDistrict()
    this.getUnion()
  },
  computed: {
      columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('warehouse_config.warehouse'), class: 'text-left' },
          { label: this.$t('warehouseFarmer.product_type'), class: 'text-left' },
          { label: this.$t('warehouseFarmer.product_name'), class: 'text-left' },
          { label: this.$t('warehouseFarmer.land_area'), class: 'text-left' },
          { label: this.$t('warehouseFarmer.delivery_date'), class: 'text-left' },
          { label: this.$t('warehouseFarmer.receipt'), class: 'text-left' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'index' },
            { key: 'warehouse_name_bn' },
            { key: 'commodity_group_name' },
            { key: 'commodity_name_bn' },
            { key: 'farmer_land_area' },
            { key: 'delivery_date' },
            { key: 'receipt' }
          ]
        } else {
          keys = [
            { key: 'index' },
            { key: 'warehouse_name' },
            { key: 'commodity_group_name' },
            { key: 'commodity_name' },
            { key: 'farmer_land_area' },
            { key: 'delivery_date' },
            { key: 'receipt' }
          ]
        }

        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    paginationDataLoad () {
      this.loadData()
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: true, listReload: false })
      const response = await RestApi.getData(authServiceBaseUrl, farmerProfile + '/' + this.$props.farmer_id)
      if (response.success) {
          this.farmerList = response.data
      }
      const result = await RestApi.getData(warehouseServiceBaseUrl, `${farDeliveryDetails}/${this.$props.farmer_id}`)
        if (result.success) {
            this.deliverySchedule = result.data
            this.currentDate = result.currentDate
          }
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: false })
    },
    getDistrict (districtId) {
      if (districtId === null || districtId === undefined) {
            return ''
      }
      const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList
      const districtData = districtList.find(district => district.value === districtId)
      return (this.$i18n.locale === 'bn') ? districtData !== undefined ? districtData.text_bn : '' : districtData !== undefined ? districtData.text_en : ''
    },
    getUnion (unionId) {
      if (unionId === null || unionId === undefined) {
                return ''
          }
      const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList
      const unionData = unionList.find(union => union.value === unionId)
      return (this.$i18n.locale === 'bn') ? unionData !== undefined ? unionData.text_bn : '' : unionData !== undefined ? unionData.text_en : ''
    },
     pdfExport () {
       const pdfData = this.getPdfData()
      const rowData = []
       const date = [
          { text: this.$t('warehouseFarmer.date'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }, { text: ': ' }, { text: this.currentDate }
        ]
        const id = this.$n(this.deliverySchedule.farmer_id, { useGrouping: false })
      const farmer = [
          { text: this.$t('warehouseFarmer.farmer_id'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }, { text: ': ' }, { text: id }
      ]
      const name = this.$i18n.locale === 'bn' ? this.$t(this.farmerList.name_bn) : this.farmerList.name
      const farmerName = [
          { text: this.$t('warehouseFarmer.farmer_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }, { text: ': ' }, { text: name }
      ]
      const father = this.$i18n.locale === 'bn' ? this.$t(this.farmerList.name_bn) : this.farmerList.name
      const fatherName = [
          { text: this.$t('warehouseFarmer.father_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }, { text: ': ' }, { text: father }
      ]
      const village = this.getUnion(this.farmerList.union_id)
      const villageName = [
          { text: this.$t('warehouseFarmer.village'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }, { text: ': ' }, { text: village }
      ]

      const district = this.getDistrict(this.farmerList.district_id)
      const districtName = [
          { text: this.$t('warehouseFarmer.district'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }, { text: ': ' }, { text: district }
      ]

     // footer part
      const totallist = [
        { text: '' }, { text: '' }, { text: this.$t('warehouseFarmer.total') + ' .........................', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12), margin: [-150, 0, 0, 8] }
      ]
      const applicationlist = [
         { text: this.$t('warehouseFarmer.application_loan_rate') + ' ...........................................................................................', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }, { text: '' }, { text: '' }
      ]
       const wordsList = [
         { text: this.$t('warehouseFarmer.in_words'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }, { text: '' }, { text: '' }
      ]
      const warehousekeeper = [
         { text: '' }, { text: '' }, { text: this.$t('warehouseFarmer.warehouse_kepper') + ' ..............................', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12), margin: [50, 0, 0, 0] }
      ]
      const labels = [
          { text: this.$t('warehouseFarmer.sack_quantity'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouseFarmer.gain_description'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouseFarmer.sackNo'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouseFarmer.weight'), colSpan: 2, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: '' }
        ]
      const labels1 = [
      { text: '' },
      { text: '' },
      { text: '' },
      { text: this.$t('warehouseFarmer.quintal'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
      { text: this.$t('warehouseFarmer.kilogram'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
    ]
      // rowData.unshift(date, farmer, farmerName, labels, labels1)
      const extraData = {
        totalHeaderRows: 2,
        subHead: [date, farmer, farmerName, fatherName, villageName, districtName],
        footer: [totallist, applicationlist, wordsList, warehousekeeper]
      }
      rowData.unshift(labels, labels1)
      rowData.push(pdfData)
      const reportTitle = this.$i18n.locale === 'en' ? 'Product Delivery Receipt' : 'পণ্য বিতরণ প্রাপ্তি'
      const columnWids = ['20%', '20%', '20%', '20%', '20%']
      // const extra = {
      //   totalHeaderRows: 2
      // }
      ExportPdf.exportPdf(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', 13, reportTitle, rowData, columnWids, extraData)
    },
    getPdfData () {
      const cropName = this.$i18n.locale === 'bn' ? this.$t(this.deliverySchedule.commodity_name_bn) : this.deliverySchedule.commodity_name
      const cropType = this.$i18n.locale === 'bn' ? this.$t(this.deliverySchedule.commodity_group_name_bn) : this.deliverySchedule.commodity_group_name
        const delivery = [
            { text: this.$n(this.deliverySchedule.sack_quantity) },
            { text: cropType + ' >> ' + cropName },
            { text: '' },
            { text: this.$n(this.deliverySchedule.quintal) },
            { text: '' }
          ]
        return delivery
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
 .total {
   margin-left: 120px;
 }
 .receipt-footer{
   color: #000;
   margin-top: -20px;
 }
 .keeper{
  border-top: 1px dashed black;
  margin-top:-120px;
 }
</style>
